<template>
    <div >
      <h2>今日夜班值班人员</h2>
      <table border="1">
        <thead>
        <tr>
            <td colspan="3" class="header-data">{{gettoday}}</td>
        </tr>
        </thead>
        <tbody>
            <tr class="table-header2">
                <td>部门</td>
                <td>姓名</td>
                <td>联系电话</td>
              </tr>
          <tr v-for="(item, index) in scheduleData" :key="index">
            <td>{{ item.department | formatDepartment }}</td>
            <td>{{ item.name }}</td>
            <td><a :href="'tel:' + item.phone">{{ item.phone }}</a></td>
          </tr>
          <tr>
            <td colspan=4 class="phone-data">爱琴海应急电话：2958119 街区应急电话：2577119</td>
          </tr>
          <tr>
            <td colspan=4>
              <!-- <TodayWeather/> -->
               <ThreedaysWeather/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
import axios from 'axios';
// import TodayWeather from './TodayWeather.vue';
import ThreedaysWeather from './ThreedaysWeather.vue';
  
  export default {
    name: 'ScheduleTable',
    components: {
      // TodayWeather
      ThreedaysWeather
    },
    data() {
      return {
        scheduleData: [],
        weatherData: {},
        weatherLoading: false,
        weatherError: false,
        weatherErrorMsg: '',
      };
    },
    mounted() {
      this.fetchTodaysSchedule();
    },
    methods: {  
      async fetchTodaysSchedule() {
        try {
          const response = await axios.get('http://47.92.113.51/api/todays-schedule');
          this.scheduleData = response.data.map((item, index) => ({
            department: `部门${index + 1}`, // 假设部门名称为部门1、部门2等
            name: item.name,
            phone: item.phone
          }));
        } catch (error) {
          console.error('Failed to fetch schedule:', error);
          alert('Failed to fetch schedule');
        }
      },
    },
    filters:{
        formatDepartment(value){
            if(value === '部门1'){
                return '总值班';
            }else if(value==='部门2'){
                return '工程物业值班';
            }else if(value==='部门3'){
                return '爱琴海消防监控室';
            }else if(value==='部门4'){
                return '爱琴海消防监控室';
            }else if(value==='部门5'){
                return '爱琴海水暖';
            }else if(value==='部门6'){
                return '爱琴海强电';
            }else if(value==='部门7'){
                return '爱琴海综合维修';
            }else if(value==='部门8'){
                return '老街消防监控室';
            }else if(value==='部门9'){
                return '老街工程';
            }else if(value==='部门10'){
                return '爱琴海商管';
            }else if(value==='部门11'){
                return '爱琴海商管';
            }else if(value==='部门12'){
                return '爱琴海商管';
            }else if(value==='部门13'){
                return '爱琴海商管';
            }else if(value==='部门14'){
                return '爱琴海秩序';
            }else if(value==='部门15'){
                return '爱琴海秩序';
            }else if(value==='部门16'){
                return '爱琴海秩序';
            }else if(value==='部门17'){
                return '爱琴海秩序';
            }else if(value==='部门18'){
                return '爱琴海秩序';  
            }else if(value==='部门19'){
                return '老街秩序班长';}
      }
    },
    computed: {
      gettoday() {
    let date = new Date();
    let currentHour = date.getHours();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (currentHour < 9) {
      // 如果当前时间是09:00之前，显示前一天的日期
      day -= 1;
      if (day === 0) {
        // 如果是月初，需要处理月份和年份的边界情况
        month -= 1;
        if (month === 0) {
          month = 12;
          year -= 1;
        }
        // 获取上个月的天数
        const daysInPreviousMonth = new Date(year, month, 0).getDate();
        day = daysInPreviousMonth;
      }
    }

    return `${year}年${month}月${day}日`;
  },
    }
  };
  </script>
  
  <style scoped>
  table {
    width: 100; /* 使用百分比宽度，让表格自适应容器 */
    border-collapse: collapse; /* 合并边框，消除间隙 */
    margin: 20px auto;
    color: #000;
  }
  .table-header2 td{
    background-color: #f2f2f2;
    padding: 8px;
    font-weight: bold;
    color: #000;
  }
  thead tr td{
    background-color: #f2f2f2;;
    padding: 8px;
    font-weight: bold;
    color: #000;
  }
  th, td {
    width: 200px;
    background-color: #fefefe;;
    border: 1px solid #ddd;
    padding: 4px;
    text-align: center;
  }
  h2{
    width: 600px;
    text-align: center;
    margin: 2px auto;
    color: #000;

  }
  .header-data{
    font-size: 20px;
    text-align: center;
    margin: 10px 0;
    color: #000;
  }
  .phone-data{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
    color: #000;
  }
  </style>