<template>
    <div class="weather-info">
        <h2>三日天气（泰安岱岳区）</h2>
        <div v-if="weatherLoading" class="loading">加载中...</div>
        <div v-else-if="weatherError" class="error">获取天气失败，请稍后重试。</div>
        <div v-else class="weather-details">
          <!-- 通过 v-for 循环展示三天天气 -->
          <div v-for="(day, index) in threeDayWeather" :key="index" class="weather-day">
            <div class="weather-icon">
              <!-- 根据天气类型显示图标 -->
              <img v-if="day.condition.includes('晴')" src="@/assets/Sunny.png" alt="晴">
              <img v-else-if="day.condition.includes('雨')" src="@/assets/Rain.png" alt="雨">
              <img v-else src="@/assets/Cloudy.png" alt="多云">
            </div>
            <div class="weather-stats">
              <p class="date">{{ day.formattedDate }}</p> <!-- 增加日期显示 -->
              <p class="temperature">{{ day.temp }}</p>
              <p class="condition">{{ day.condition }}</p>
              <p class="details">风力：{{ day.wind }} | 湿度：{{ day.humidity }}</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
name: 'TodayWeather',
data() {
    return {
    threeDayWeather: [], // 用于存储三天的天气数据
    weatherLoading: false,
    weatherError: false,
    weatherErrorMsg: '',
    };
},
created() {
    this.fetchWeather().catch(error => {
      console.error('初始化天气数据失败：', error);
    });
  },
  methods:{
    async fetchWeather() {
      this.weatherLoading = true;
      this.weatherError = false;
      try {
        const API_KEY = '23860afc5f5641b8968c54a265c76aea';
        const CITY_ID = '101120807';
        const API_URL = `https://devapi.qweather.com/v7/weather/3d?location=${CITY_ID}&key=${API_KEY}`;
        
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error(`HTTP 错误！状态码：${response.status}`);
        const data = await response.json();
        console.log('API 返回数据：', JSON.stringify(data, null, 2));

        if (data.code !== '200') {
          throw new Error(`API 错误：${data.code} - ${data.message || '未知错误'}`);
        }

        if (!data.daily || data.daily.length < 3) {
          throw new Error('API 返回数据不足三天');
        }

        // 提取三天的天气数据并格式化日期
        const threeDays = data.daily.slice(0, 3);
        this.threeDayWeather = threeDays.map(day => ({
          formattedDate: this.formatDate(day.fxDate), // 格式化日期
          city: '泰安',
          condition: day.textDay || '未知',
          temp: `${day.tempMin || '--'}°C ~ ${day.tempMax || '--'}°C`,
          wind: (day.windDirDay || '未知') + ' ' + (day.windScaleDay || '0') + '级',
          humidity: `${day.humidity || '--'}%`,
        }));

      } catch (error) {
        console.error('获取天气失败：', error);
        this.weatherError = true;
        this.weatherErrorMsg = error.message;
      } finally {
        this.weatherLoading = false;
      }
    },
    // 日期格式化函数
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = { month: 'long', day: 'numeric', weekday: 'short' };
      return new Intl.DateTimeFormat('zh-CN', options).format(date);
    },
  }
}
</script>

<style scoped>
.weather-info {
    width: 600px; /* 调整宽度以容纳三天数据 */
    text-align: left;
    margin: 20px auto; /* 居中对齐 */
    padding: 20px;
    background: #f5f7fa;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .weather-info h2 {
    text-align: center;
    margin: 0 0 15px 0;
    color: #2c3e50;
    font-size: 1.5em;
  }
  .weather-info h3 {
    margin: 0 0 15px 0;
    color: #2c3e50;
    font-size: 1.2em;
  }

  /* 三天天气的容器 */
  .weather-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  /* 每天天气的布局 */
  .weather-day {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .weather-icon img {
    width: 40px;
    height: 40px;
  }

  .temperature {
    font-size: 18px;
    font-weight: bold;
    color: #34495e;
    margin: 0;
  }

  .date {
    font-size: 0.9em;
    color: #7f8c8d;
    margin-bottom: 5px;
  }

  .condition {
    color: #7f8c8d;
    margin: 5px 0;
  }

  .details {
    color: #95a5a6;
    font-size: 0.8em;
    margin: 0;
  }

  .loading, .error {
    text-align: center;
    color: #e74c3c;
    padding: 10px;
  }
</style>